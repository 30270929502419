.privacy {
    text-decoration: none;
    color: var(--gray);
    padding: 12px;
}

.logo {
    width: 125px;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 200px;
    height: 100%;
}

.terms {
    display: flex;
    justify-content: center;
}

.leftIcon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.backFont {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-decoration: none;
    color: var(--black);
}

.backLoginDiv {
    width: 51%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 15px 0px;

}

.vecloudLogo {
    width: 200px;
    margin: 20px 0px;
}

.desc {
    font-weight: 400;
    font-size: 16px;
    margin: 15px 0px;
}

.title {
    font-size: 20px;
    margin: 0px;
}

.emailInput {
    width: 99%;
    height: 20px;
}

.forgetPassDiv {
    width: 60%;
    border-color: var(--lightGray);
    margin: auto;
}

.resetButton {
    border: none;
    border-radius: 5px;
    width: 100%;
    padding: 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: center;
}