.progress-bar {
    height: 24px;
    color: var(--white);
    text-align: right;
    line-height: 24px;
    border-radius: 8px;
    transition: width 0.5s ease-in-out, background-color 0.5s ease-in-out;
}


.progress-container {
    display: flex;
    align-items: center;
    position: relative;
}
  
.custom-progress-bar {
    flex-grow: 1;
    margin: 1% 0;
    height: 10%;
}

.progress-label-start {
    position: absolute;
    left: 0;
    transform: translateX(30%);
}

.progress-label-end {
    position: absolute;
    right: 0;
    transform: translateX(-45%);
}

.progress-bar.bg-success {
    background-color: #4BE14B !important; /* Green */
}

.progress-bar.bg-warning {
    background-color: #FFC832 !important; /* Yellow */
}

.progress-bar.bg-danger {
    background-color: #F04B32 !important; /* Red */
}

.progress-bar.bg-primary {
    background-color: lightgrey !important; /* Light Grey */
}