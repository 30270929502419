:root {
  --primary-color: #4caf50;
  --secondary-color: #ff9800;
  --text-color: #333;
  --background-color: #f5f5f5;
  --white: #fafafa;
  --lightGray: #D3D3D3;
  --green:#4be14b;
  --red: #F04B32;
  --yellow: #FFC832;
  --black: #444444;
  --gray: gray;
  --lightBlue: #ADD8E6;
  --blue: #0000FF;
  --chart-color-red: #FF638433;
  --chart-color-blue: #36A2EB33;
  --chart-color-yellow: #FFCE5633;
  --chart-color-green: #4BC0C033;
  --chart-color-purple: #9966FF33;
  --chart-color-orange: #FF9F4033;
  --chart-color-border-red: #FF6384;
  --chart-color-border-blue: #36A2EB;
  --chart-color-border-yellow: #FFCE56;
  --chart-color-border-green: #4BC0C0;
  --chart-color-border-purple: #9966FF;
  --chart-color-border-orange: #FF9F40;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
