.salesTrendChart {
    width: 100%;
    text-align: left;
    padding: 1rem 0rem;
}

@media (max-width: 768px) {
    .salesTrendChart {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

@media (max-width:1023px) {
    .salesTrendChart {
        min-width: 100%;
    }
}