.container {
    width: 100vw;
    min-height: 100vh;
}

.machineTitle {
    width: 100%;
}

.area {
    width: 45%;
    border-right: 1px solid lightgrey;
    padding: 0rem 1rem;
}

.machineArea {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 0rem;
    max-height: 150rem;
    padding-top: 1rem;
}

.machineTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.handleSaleTrans {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem 1rem 0rem;
}

.handleSaleCharts {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.realtimeDiv {
    width: 40%;
    padding: 0rem 1rem;
    display: flex;
    justify-content: flex-start;
}

.rdrDefinedRangesWrapper {
    display: none;
}

#dropdown-basic {
    min-width: 200px;
}

#dropdown-basic::after {
    display: none !important; /* Hides the default Bootstrap arrow */
}

@media (min-width:1200px) {
    .machineArea {
        min-height: 0rem;
    }

    .handleSaleTrans {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        min-width: 100%;
    }
}

@media (max-width: 1023px) {
    .area {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border: none;
        padding: 0rem 1rem;
    }
    .machineTitle {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .machineArea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        min-height: 126rem;
        padding-top: 10rem;
    }
    .handleSaleTrans {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        min-width: 100%;
        min-height: 15rem;
    }
    .handleSaleCharts {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 100%;
    }
    .realtimeDiv {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .machineArea {
        min-height: 90rem;
    }
}

@media (max-width: 480px) {
    .machineArea {
        min-height: 90rem;
        padding-top: 7rem;
    }
    .handleSaleTrans {
        min-width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .handleSaleCharts {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left:0px;
    }
    .realtimeDiv {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}

/* Extra-small screens (max-width 479px) */
@media (max-width: 479px) {
    .machineArea {
        display: flex;
        flex-direction: column; /* Stack children vertically */
        align-items: stretch; /* Ensure full-width children */
        gap: 1rem; /* Add spacing between .area and .realtimeDiv */
        flex-wrap: nowrap;
        padding-top: 10rem;
    }

    .area {
        width: 100%;
    }

    .realtimeDiv {
        width: 100%;
    }
}

@media (max-width: 320px) {
    .machineArea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-top: 20rem;
    }
    .area {
        width: 100%;
        border: none;
    }

    .machineTitle {
        display: flex;
        flex-direction: column;
    }
    
    .handleSaleCharts {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .realtimeDiv {
        width: 100%;
    }
}