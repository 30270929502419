.flexEnd {
    text-align: right;
}

.lblMachineHealth {
    text-align: left;
    font-weight: 600;
}

.greenDot {
    min-width: 0.5rem;
    min-height: 0.5rem;
    border-radius: 50%;
    background-color: var(--green);
}

.redDot {
    min-width: 0.5rem;
    min-height: 0.5rem;
    border-radius: 50%;
    background-color: var(--red);
}

.healthMachineIcon {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.green-icon {
    min-width: 0.8rem;
    max-width: 0.8rem;
    color: var(--green);
}

.yellow-icon {
    min-width: 0.8rem;
    max-width: 0.8rem;
    color: var(--yellow);
}

.red-icon {
    min-width: 0.8rem;
    max-width: 0.8rem;
    color: var(--red);
}

.fontHandle {
    font-size: 0.9rem;
}

.fontHandle::first-letter {
    text-transform: capitalize;
}