.totalSalesDiv {
    min-width: 11.5rem;
    max-width: 5rem;
    min-height: 4.5rem;
    max-height: 6rem;
}

.totalSalesLabel {
    background-color: #2E3E80;
    color: #fff;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.salesValue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 16px;
    padding: 5%;
    font-weight: 800;
    background-color: #E1F0FF;
    color: #222222;
}

@media (max-width: 1001px) and (max-width:1200px) {
    .totalSalesDiv {
        min-height: 4.5rem;
        max-height: 6rem;
        max-width: 4rem;
        min-width: 12rem;
    }
}

@media (max-width: 767px) and (max-width:1100px) {
    .totalSalesDiv {
        min-height: 4.5rem;
        max-width: 4rem;
        min-width: 12rem;
    }
    
}
@media (max-width: 480px) {
    
    .totalSalesDiv {
        min-height: 4.5rem;
        max-width: 4rem;
        min-width: 15rem;
    }
    
}