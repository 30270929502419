/*sidebar css*/
/* .sidebar {
    height: 100vh;
    background-color: #f8f9fa;
    transition: width 0.3s;
    overflow-x: hidden;
    position: fixed;
    top: 7%;
    left: 0;
    width: 15%;
    z-index: 1;
    margin: 20px 0px;
} */
.sidebar {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    padding-top: 65px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    border-right: 1px solid #dee2e6;
    transition: width 0.5s;
}
.sidebar-container {
    height: 100vh;
}

.content-container {
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    transition: margin-left 0.3s ease;
}

.ps-sidebar-root .ps-collapsed .sidebar-container .css-1wvake5 {
    min-height: 100vh;
    max-height: 100vh;
    height: 100%;
}

.css-dip3t8 {
    height: 100vh;
}

.css-1wvake5.ps-collapsed {
    min-height: 100vh;
}

.sidebar.collapsed {
    width: 4.5%;
}

.sidebar.expanded {
    width: 20%;
}

.sidebar-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 1rem;
}

.icon {
    margin-right: 10px;
}

.ve-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40px;
    margin: 10px 0px;
    margin-right: 1rem;
}

.link-underline {
    text-decoration: none;
    color: var(--black);
    cursor: pointer;
}

.text-input-width {
    width: 100%;
}

@media (min-width: 319px) {
    .sidebar.expanded {
        width: 60%;
    }
    .sidebar.collapsed {
        width: 0%;
    }
}

@media (min-width: 429px) {
    .sidebar.expanded {
        width: 60%;
    }
    .sidebar.collapsed {
        width: 0%;
    }
}

@media (min-width: 767px) and (max-width: 1023px) {
    .sidebar.expanded {
        width: 30%;
    }
    .sidebar {
        height: 100%;
        width: 100% ;
        margin-top: 0px;
    }
    .sidebar.collapsed {
        width: 0%;
        margin-top: 0%;
    }
}

@media (min-width: 1024px) {
    .sidebar.collapsed {
        width: 8%;
        margin-top: 0%;
        .left-icon {
            display: none;
        }
    }
    .sidebar.expanded {
        width: 25%;
    }
}

@media (min-width: 1439px) {
    .sidebar.collapsed {
        width: 6%;
        margin-top: 0%;
        .left-icon {
            display: none;
        }
    }
    .sidebar.expanded {
        width: 15%;
    }
}