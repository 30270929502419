.row {
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap;
    justify-content: flex-start; */
    gap: 8px;
    margin-bottom: 16px;
    overflow-x: auto;
}

.row-slot {
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap;
    justify-content: flex-start; */
    gap: 8px;
    margin-bottom: 16px;
    overflow-x: auto;
}

.row-label {
    margin-bottom: 8px;
}

.label {
    width: 100%;
    margin-bottom: 20px;
    text-align: right;
}

.slot-status {
    min-width: 80px;
    min-height: 30px;
    display: inline-block;
    margin-bottom: 2px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
}

.occupied, .error, .ofs, .empty, .available, .pendingDeposit, .pendingCollection, .collecting, .adminCheck, .reserved, .expiredBeforeDeposit, .expiredBeforeCollection, .refill, .nonInitialized, .emptyBoard {
    min-width: 80px;
    min-height: 30px;
    display: inline-block;
    border: 1px solid;
    border-radius: 10px;
    margin-right: 7px;
    margin-bottom: 2px;
    padding: 6px;
    text-align: center;
    font-size: 12px;
}

.occupied {
    background-color: #b2ff59;
}

.error {
    background-color: #ff8a80; /* colors.red[200] */
}

.ofs {
    background-color: #ffcc80; /* colors.orange[200] */
}

.empty {
    background-color: #f5f5f5; /* colors.grey[100] */
}

.available {
    background-color: #B9F6CA;
}

.pendingDeposit {
    background-color: #BBDEFB;
}

.pendingCollection {
    background-color: #FFE0B2;
}

.collecting {
    background-color: #90CAF9;
}

.adminCheck {
    background-color: #E0E0E0;
}

.reserved {
    background-color: #FFF9C4;
}

.expiredBeforeDeposit {
    background-color: #FFCDD2;
}

.expiredBeforeCollection {
    background-color: #EF9A9A;
}

.refill {
    background-color: #ffcc80;
}

.nonInitialized {
    background-color: #f5f5f5;
}

.emptyBoard {
    background-color: #90CAF9;
}

.card {
    overflow-y: hidden;
    overflow-x: scroll;
    border: none;
    min-width: 92vw;
}

.slot {
    position: relative;
    max-width: 130px;
    max-height: 130px;
    min-width: 130px;
    min-height: 130px;
    border: 1px solid #808080;
    border-radius: 4px;
    padding: 5px;
    font-size: 10px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.slot .slot-label {
    font-weight: bold;
    font-size: 12px;
    /* position: absolute;
    top: 5px;
    left: 5px; */
    text-align: center;
}

.slot .product-name {
    font-size: 14px;
}

.slot .quantity {
    font-size: 12px;
}

/* CustomCheckboxGroup.css */
.custom-checkbox-label {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}

.custom-checkbox {
    background-color: #e0e0e0; /* Light gray background */
    border-radius: 4px;
    padding: 5px;
    margin-right: 10px;
}

.custom-checkbox.checked {
    background-color: #2196f3; /* Blue background when checked */
    color: #fff;
}

.custom-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}