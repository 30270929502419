.transFreLabel {
    background-color: var(--black);
    color: var(--white);
    border-radius: 0px 0px 20px 0px;
    width: 50%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.transFreValue {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    font-size: 24px;
    padding-right: 5%;
    padding-bottom: 1%;
    font-weight: 800;
}

@media (max-width: 1101px) and (max-width:1200px) {
    .transFreDiv {
        min-height: 4.5rem;
        max-height: 6rem;
        max-width: 4rem;
        min-width: 12rem;
    }
}

@media (max-width: 767px) and (max-width:1100px) {
    .transFreLabel {
        width: 50%;
        height: 40%;
        display: flex;
        font-size: 12px;
    }
    .transFreDiv {
        min-width: 12rem;
        max-width: 20rem;
        min-height: 4.5rem;
    }
}

@media (max-width: 480px) {
    .transFreDiv {
        min-width: 15rem;
        max-width: 4rem;
        min-height: 4.5rem;
    }
}