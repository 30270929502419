/* dashboard css */

.content {
    width: 100%;
}

.machineRealtimeTitle {
    width: 100%;
    display: flex;
}

.machineBody {
    width: 60%;
}

.realtimeStatus {
    width: 100%;
}

.handleDiv {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

/* @media (max-width: 768px) {
    .lblTotalSales {
        top: 43%;
        left: 11%;
        width: 10%;
    }
    
    .lblTotalSalesValue {
        width: 100%;
        height: 100%;
    }

    .lblTransFre {
        top: 43%;
        left: 37%;
        width: 10%;
    }

    .lblTransFreValue {
        width: 100%;
        height: 100%;
    }
} */