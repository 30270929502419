.lblLatestTrans {
    text-align: left;
    font-weight: 600;
}

.lblProductTable {
    text-align: left;
    font-weight: 600;
}

.lblMachineTable {
    text-align: left;
    font-weight: 600;
}

.macTabDiv {
    min-width: 100%;
    max-width: 45%;
}

.proTableDiv {
    min-width: 100%;
    max-width: 45%;
    padding: 1rem 0rem;
}

@media (max-width:1023px) {
    .proTableDiv {
        min-width: 100%;
    }
    .macTabDiv {
        min-width: 100%;       
    }
}

@media (max-width: 768px) {
    .proTableDiv {
        min-width: 100%;
    }
    .macTabDiv {
        min-width: 100%;
        
    }
}

