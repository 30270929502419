.realtimeArea {
    width: 100%;
}

.lblRealtime {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.overallDiv {
    padding: 1rem 0rem;
    /* max-width: 80%; */
}

.machineHealthDiv {
    /* max-width: 80%; */
}

.latest-trans-div {
    padding: 1rem 0rem;
    /* max-width: 80%; */
}