.salesChartDiv {
    width: 100%;
    padding: 1rem 0rem;
}

.lblSalesChart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
}

.chart-wrapper {
    width: 100%;
    height: 50vh;
}

@media (max-width:1023px) {
    .salesChartDiv {
        min-width: 100%;
    }
}

@media (max-width: 768px) {
    .salesChartDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .chart-wrapper {
        width: 100%;
    }
}